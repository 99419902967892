.large-title {
    font-size: 40px !important;
}
.medium-title {
    font-size: 20px !important;
}
.small-time {
    font-size: 15px !important;
    font-weight: lighter !important;
}
.small-title {
    font-size: 15px !important;
    font-weight: bolder !important;
}
.small-text {
    font-size: 15px !important;
}